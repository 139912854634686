import cx from 'classnames';
import type * as React from 'react';
import { useState } from 'react';
import { Spinner } from '@dx-ui/osc-spinner';
import { Alert } from '@dx-ui/osc-alert';
import { Pill } from './pill';
import type { TFunction } from 'i18next';
import { useTranslation, Trans } from 'next-i18next';
import type { Locale } from '@dx-ui/framework-uri-builder';
import { bookUriBuilder } from '@dx-ui/framework-uri-builder';
import { addDays, format, parseISO, startOfToday } from 'date-fns';
import { slugifyBrand } from './slugify-brand';
import { Dialog } from '@dx-ui/osc-dialog';
import { LoginIFrame } from '@dx-ui/osc-login';
import { useAuth } from '@dx-ui/framework-auth-provider';
import type {
  StaticOfferFragment,
  StaticOfferQuery,
  StaticOfferQueryVariables,
} from '../generated/types';
import { useStaticOfferQuery } from '../generated/queries';
import Markdown from 'markdown-to-jsx';
import type { Maybe } from '@dx-ui/gql-types';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import type { BreadcrumbsProps } from '@dx-ui/osc-breadcrumbs';
import { Breadcrumbs } from '@dx-ui/osc-breadcrumbs';
import { ShopFormDates, ShopFormLocation } from './shop-form-imports';

const ShopForm = dynamic(() => import('./shop-form-imports').then((mod) => mod.ShopForm), {
  ssr: false,
});

export function DisclosureText({
  heading,
  children,
}: {
  heading: string;
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <button
        className="mb-2 block text-sm font-bold"
        onClick={() => setOpen((s) => !s)}
        aria-expanded={open}
        type="button"
      >
        <span className="mr-2">{heading}</span>
        <svg
          className={cx('inline-block h-3 w-3 transform align-baseline transition', {
            'rotate-180': open,
          })}
          fill="currentColor"
          viewBox="0 0 11 8"
        >
          <path d="M5.5 8L0 0h11z" />
        </svg>
      </button>
      {open && <div className="text-sm">{children}</div>}
    </div>
  );
}

interface CommonOfferProps {
  offerId: number;
  language: string;
  brandCode?: string;
  breadCrumbs?: BreadcrumbsProps;
  /**
   * If this is set a button will appear "View Available Dates" that will take the user to the flex dates calendar of that hotel
   */
  ctyhocn?: string;

  baseUrl: string;
  autocompleteUri: string;
  signInIframeUrl: string;
  renderAdditionalComponents?: (
    data: StaticOfferQuery & { firstImageForOg?: string }
  ) => React.ReactNode;
}

export function OfferDetails(props: CommonOfferProps) {
  const { data, isLoading } = useStaticOfferQuery<StaticOfferQuery, StaticOfferQueryVariables>({
    offerId: props.offerId,
    language: props.language,
    ctyhocn: props.ctyhocn || '',
    includeHotel: props.ctyhocn !== undefined,
    brandCode: props.brandCode || '',
  });
  const { t } = useTranslation('offers');
  const { login, isAuthenticated } = useAuth();
  return (
    <OffersDetailsDisplay
      data={data}
      loading={isLoading}
      brandCode={props.brandCode}
      autoCompleteUri={props.autocompleteUri}
      locale={props.language}
      baseUrl={props.baseUrl}
      ctyhocn={props.ctyhocn}
      login={login}
      isAuthenticated={isAuthenticated}
      t={t}
      signInIframeUrl={props.signInIframeUrl}
      renderAdditionalComponents={props.renderAdditionalComponents}
    />
  );
}

export function OffersDetailsDisplay({
  data,
  ctyhocn,
  loading,
  brandCode,
  locale,
  baseUrl,
  autoCompleteUri,
  login,
  isAuthenticated,
  t,
  signInIframeUrl,
  renderAdditionalComponents,
}: {
  data?: StaticOfferQuery;
  ctyhocn?: string;
  loading: boolean;
  brandCode?: string;
  locale: string;
  baseUrl: string;
  autoCompleteUri: string;
  login: ReturnType<typeof useAuth>['login'];
  isAuthenticated: ReturnType<typeof useAuth>['isAuthenticated'];
  t: TFunction<'offers'>;
  signInIframeUrl: string;
  renderAdditionalComponents?: CommonOfferProps['renderAdditionalComponents'];
}) {
  const [isSignIModalOpen, setIsSignIModalOpen] = useState(false);

  if (loading) {
    return (
      <div className="flex items-center justify-center py-8">
        <Spinner size="lg" className="text-primary" />
      </div>
    );
  }
  const staticOffer = data?.staticOffer;
  if (!staticOffer) {
    return <Alert title="Offer not found" status="urgent" />;
  }
  const firstImage = data?.staticOffer?.images?.[0]?.ogImage || '';
  const showViewDatesButton = ctyhocn && staticOffer?.ctyhocns?.includes(ctyhocn);
  const img = staticOffer?.images?.[0];
  const imgAlt = img?.altText || '';
  const imgSrc = img?.variants?.find((v) => v.size === 'md')?.url || '';
  return (
    <div>
      {renderAdditionalComponents &&
        renderAdditionalComponents({ ...data, firstImageForOg: firstImage })}
      <div className="container pb-4 sm:flex sm:flex-wrap sm:pt-4">
        {img ? (
          <div className="-mx-4 sm:order-3 sm:mx-0 sm:w-5/12">
            <img data-testid="banner-img" className="w-full" src={imgSrc} alt={imgAlt} />
          </div>
        ) : null}
        <div className="mb-3 pt-3 sm:w-full sm:pt-0 lg:mb-5">
          <DetailsBreadCrumbs data={data} brandCode={brandCode} locale={locale} t={t} />
        </div>

        <Dialog
          isOpen={isSignIModalOpen}
          title={t('signIn')}
          ariaLabel="Login Modal"
          onDismiss={() => setIsSignIModalOpen(false)}
        >
          <LoginIFrame
            frameSrc={signInIframeUrl}
            options={undefined}
            data-e2e="loginIframe"
            onLoginAttempt={async ({ data }) => {
              if (data) {
                await login({ data });
                setIsSignIModalOpen(false);
              }
            }}
            onClose={() => setIsSignIModalOpen(false)}
            title={t('signIn')}
          />
        </Dialog>
        <div className="sm:w-7/12 sm:pr-5 lg:w-6/12">
          <h2 className="text-primary mb-2 text-2xl font-bold leading-tight sm:text-3xl">
            {staticOffer?.headline}
          </h2>
          <div className="space-y-4">
            <p>{staticOffer?.longDescription || staticOffer?.shortDescription}</p>
            {staticOffer?.keySellingPoint?.length ? (
              <ul className="list-outside list-disc pl-4">
                {staticOffer?.keySellingPoint.map((p) => (
                  <li key={p}>{p}</li>
                ))}
              </ul>
            ) : null}
            {(staticOffer?.bookStartFmt || staticOffer?.stayStartFmt) && (
              <div>
                <div>
                  <span className="sr-only">
                    {t('bookDatesA11y', {
                      start: staticOffer?.a11yBookStartFmt,
                      end: staticOffer?.a11yBookEndFmt,
                    })}
                  </span>

                  <div aria-hidden="true">
                    <Trans
                      t={t}
                      i18nKey="bookByDates"
                      values={{
                        bookStart: staticOffer?.bookStartFmt,
                        bookEnd: staticOffer.bookEndFmt,
                      }}
                    >
                      <span className="mr-1 font-bold">Book by dates:</span>
                      <span>
                        {staticOffer?.bookStartFmt} - {staticOffer.bookEndFmt}
                      </span>
                    </Trans>
                  </div>
                </div>
                <div>
                  <span className="sr-only">
                    {t('stayDatesA11y', {
                      start: staticOffer?.a11yStayStartFmt,
                      end: staticOffer?.a11yStayEndFmt,
                    })}
                  </span>
                  <div aria-hidden="true">
                    <Trans
                      t={t}
                      i18nKey="stayByDates"
                      values={{
                        stayStart: staticOffer.stayStartFmt,
                        stayEnd: staticOffer?.stayEndFmt,
                      }}
                    >
                      <span className="mr-1 font-bold">Stay dates:</span>
                      <span>
                        {staticOffer?.stayStartFmt} - {staticOffer?.stayEndFmt}
                      </span>
                    </Trans>
                  </div>
                </div>
              </div>
            )}
            <div className="space-x-2">
              {staticOffer?.attributes?.map((attr) => (
                <Pill key={attr.id}>{attr?.name}</Pill>
              ))}
            </div>
            {staticOffer?.honors && (
              <div className="font-bold">
                {isAuthenticated && t('honorsOfferNotificationLoggedIn')}
                {!isAuthenticated && (
                  <Trans t={t} i18nKey="honorsOfferNotificationLoggedOut">
                    Exclusive to Hilton Honors members.
                    <a
                      href="https://www.hilton.com/en/hilton-honors/join/"
                      className="btn-primary-text underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Join
                    </a>{' '}
                    (for free) or
                    <button
                      className="btn-primary-text underline"
                      onClick={() => setIsSignIModalOpen(true)}
                      type="button"
                    >
                      Sign In
                    </button>
                    .
                  </Trans>
                )}
              </div>
            )}
            {showViewDatesButton ? (
              <a
                className="btn btn-xl btn-primary"
                href={getFlexDatesUrl({
                  ctyhocn,
                  language: locale as keyof Locale,
                  ohwBaseUrl: baseUrl,
                  date: staticOffer?.stayStart || format(startOfToday(), 'yyyy-MM-dd'),
                  offerId: staticOffer.id.toString(),
                })}
                target="_blank"
                referrerPolicy="no-referrer"
                rel="noreferrer"
              >
                {t('viewAvailableDates')}
              </a>
            ) : (
              <ShopForm
                defaultValues={{
                  brandCode,
                  dates: {
                    arrivalDate: new Date(),
                    departureDate: addDays(new Date(), 1),
                  },
                  specialRates: {
                    offerId: {
                      id: staticOffer.id,
                    },
                    useOfferId: true,
                  },
                }}
                language={locale}
                wrapperClassName="max-w-sm"
                buttonClassName="btn btn-primary btn-lg w-full block mt-2"
                onSubmit={({ url }) => {
                  if (url) {
                    const fullUrl = new URL(`${baseUrl}${url}`);
                    window.location.assign(fullUrl);
                    return;
                  }
                }}
                targetOHWPage="search"
                shouldDisplayGeocodeErrorModal={true}
              >
                <div className="space-y-2">
                  <div className="mb-6 flex justify-center">
                    <ShopFormLocation autocompleteUri={autoCompleteUri} language={locale} />
                  </div>
                  <div className="flex justify-center">
                    <ShopFormDates language={locale} />
                  </div>
                </div>
              </ShopForm>
            )}
            {staticOffer.policy && (
              <DisclosureText heading={t('termsAndConditions')}>
                <div className="space-y-2">
                  <TermsAndConditionsContent offer={staticOffer} />
                </div>
              </DisclosureText>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function TermsAndConditionsContent({ offer }: { offer: StaticOfferFragment }) {
  // need to loop through terms components
  const terms = offer.policy?.terms;

  const components: { component: React.ReactNode; id: string }[] | undefined =
    terms?.components?.map((component) => {
      const id = component.id;
      const [name] = component.id.split('-');
      // find that id, looking through heading, description, ordered list, unordered list
      const termsComponent = terms?.[
        name as keyof Omit<typeof terms, 'components'>
      ] as unknown as Pick<
        NonNullable<typeof terms>[keyof Omit<typeof terms, 'components'>][number],
        'id' | 'value'
      >[];
      const foundComponent = termsComponent?.find(
        (possibleComponent) => possibleComponent.id === id
      );
      switch (name) {
        case 'heading':
          return {
            component: (foundComponent as Maybe<(typeof terms)['description'][number]>)?.value,
            id,
          };
        case 'description':
          return {
            component: (
              <Markdown
                options={{
                  forceWrapper: true,
                  overrides: {
                    a: {
                      props: {
                        className: 'underline text-primary',
                      },
                    },
                  },
                }}
              >
                {(foundComponent as Maybe<(typeof terms)['description'][number]>)?.value || ''}
              </Markdown>
            ),
            id,
          };
        case 'orderedList':
          return {
            component: (
              <ul className="list-disc">
                {(foundComponent as Maybe<(typeof terms)['orderedList'][number]>)?.value.map(
                  (v) => (
                    <li key={v}>{v}</li>
                  )
                )}
              </ul>
            ),
            id,
          };
        case 'unorderedList':
          return {
            component: (
              <ol className="list-decimal">
                {(foundComponent as Maybe<(typeof terms)['unorderedList'][number]>)?.value.map(
                  (v) => (
                    <li key={v}>{v}</li>
                  )
                )}
              </ol>
            ),
            id,
          };
        default:
          return {
            component: '',
            id,
          };
      }
    });

  return components?.map(({ component, id }) => {
    return <div key={id}>{component}</div>;
  });
}

function getFlexDatesUrl({
  ctyhocn,
  date,
  language,
  offerId,
  ohwBaseUrl,
}: {
  ctyhocn: string;
  date: string;
  language: keyof Locale;
  offerId: string;
  ohwBaseUrl: string;
}) {
  const arrival = parseISO(date);
  return bookUriBuilder({
    locale: language,
    baseUrl: ohwBaseUrl,
    page: 'flexibledates',

    urlParams: {
      ctyhocn,
      dates: {
        arrivalDate: arrival,
        departureDate: addDays(arrival, 1),
      },
      rates: {
        offerId: Number(offerId),
      },
    },
  });
}

function DetailsBreadCrumbs({
  data,
  brandCode,
  locale,
  t,
}: {
  data: StaticOfferQuery | undefined;
  brandCode: string | undefined;
  locale: string;
  t: TFunction<'offers'>;
}) {
  const { asPath } = useRouter();
  if (!data || !data?.staticOffer?.name) {
    return null;
  }

  const allOffersItem = {
    name: t('breadCrumbs.allOffers'),
    uri: `/${locale}/offers/`,
  };
  // portfolio level offer
  if (brandCode === 'WW') {
    return (
      <Breadcrumbs breadcrumbs={[allOffersItem, { name: data.staticOffer.name, uri: asPath }]} />
    );
  }

  if (data?.brand?.name && !data?.hotel?.name) {
    // brand level, offer
    return (
      <Breadcrumbs
        breadcrumbs={[
          allOffersItem,
          {
            uri: `/${locale}/offers/${slugifyBrand(data?.brand?.name)}/`,
            name: t('breadCrumbs.brandOffers', {
              brand: data?.brand?.name,
            }),
          },
          { name: data.staticOffer.name, uri: asPath },
        ]}
      />
    );
  }

  // hotel level
  return (
    <Breadcrumbs
      breadcrumbs={[
        allOffersItem,
        {
          uri: `/${locale}/offers/${slugifyBrand(data?.brand?.name)}/`,
          name: t('breadCrumbs.brandOffers', {
            brand: data?.brand?.name,
          }),
        },
        {
          uri: `/${locale}/hotels/${data?.hotel?.ctyhocn.toLowerCase()}-${slugifyBrand(
            data?.hotel?.name || ''
          )}/offers/`,
          name: t('breadCrumbs.hotelOffers', {
            hotelName: data?.hotel?.name,
          }),
        },
        { name: data.staticOffer.name, uri: `/${locale}${asPath}` },
      ]}
    />
  );
}

export default OfferDetails;
